export default function Clicksign(o) {
  let r;
  let u;
  const t = `${window.location.protocol}//${window.location.host}`;
  const e = {};
  const n = function(t) {
    (e[t] || []).forEach(function(t) {
      t();
    });
  };
  const c = function(t) {
    n(t.data);
  };
  return {
    endpoint: "https://sandbox.clicksign.com",
    origin: t,
    mount(t) {
      const n = `/sign/${o}`;
      const e = `?embedded=true&origin=${this.origin}`;
      const i = this.endpoint + n + e;
      return (
        (u = document.getElementById(t)),
        (r = document.createElement("iframe")).setAttribute("src", i),
        r.setAttribute("style", "width: 100%; height: 100%;"),
        window.addEventListener("message", c),
        u.appendChild(r)
      );
    },
    unmount() {
      return (
        r &&
          (u.removeChild(r),
          (r = u = null),
          window.removeEventListener("message", n)),
        !0
      );
    },
    on(t, n) {
      return e[t] || (e[t] = []), e[t].push(n);
    },
    trigger: n
  };
}
