import React from "react";
import PropTypes from "prop-types";
import { Dialog, withStyles } from "@material-ui/core";
import Button from "../../button/Button/Button";

const styles = {
  Paper: {
    overflowY: "visible",
    margin: 15,
    borderRadius: 8
  },
  DialogBody: {
    margin: "20px 12px"
  },
  DialogTitle: {
    margin: 0,
    fontSize: 16,
    fontWeight: 300,
    padding: "0 26px",
    textAlign: "center"
  },
  DialogDescription: {
    margin: "20px 0 30px 0",
    textAlign: "center",
    fontWeight: 400,
    fontSize: 14
  },
  DialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around"
  }
};

const ActionsDialog = ({
  classes,
  open,
  handleClose,
  handleConfirm,
  title,
  description
}) => {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.Paper }}
      maxWidth="md"
    >
      <div className={`DialogBody-root ${classes.DialogBody}`}>
        <h1 className={`DialogTitle-root ${classes.DialogTitle}`}>{title}</h1>
        <p className={`DialogDescription-root ${classes.DialogDescription}`}>
          {description}
        </p>
        <div className={`DialogActions-root ${classes.DialogActions}`}>
          <Button
            href="#"
            variant="contained"
            color="secondary"
            onClick={handleClose}
          >
            Não, obrigado!
          </Button>
          <Button variant="contained" color="primary" onClick={handleConfirm}>
            Sim, desejo criar!
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

ActionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired // https://material-ui.com/pt/styles/advanced/#withstyles
};

export default withStyles(styles, { withTheme: true })(ActionsDialog);
