import { api } from "../../../utils/api";

const getClickSignKey = fieldId => {
  const enrollmentId = localStorage.getItem("enrollmentId");
  return api.post("Enrollments/getDocumentByFieldId", {
    fieldId,
    enrollmentId
  });
};

const FormGroupService = {
  getClickSignKey
};

export default FormGroupService;
